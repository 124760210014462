<!-- 电子健康卡注册(2/2) -->
<template>
  <div class="wrap">
    <van-form @submit="onSubmit">
      <van-field
        name="telNo"
        label="手机号码"
        placeholder="请输入手机号码"
        v-model="telNo"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <!-- <van-field
        readonly
        name="code"
        label="验证码"
        placeholder="验证码"
        v-model="code"
        @touchstart.stop="show = true"
      >
        <template #button>
          <van-button
            class="getCode"
            native-type="button"
            @click.stop="getCode"
          >
            {{ codeText }}
          </van-button>
        </template>
      </van-field>
      <van-number-keyboard
        :show="show"
        v-model="code"
        close-button-text="完成"
        @blur="show = false"
      />
      <p class="psText colorhui">
        手机号主要用于接收挂号，缴费、检验检查报告进度等通知
      </p> -->
      <!-- <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >下一步，绑定本院就诊卡</van-button
        >
      </div> -->
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >完成注册</van-button
        >
      </div>
    </van-form>
    <van-overlay :show="crossRegisterState">
      <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "addCardTwo",
  data() {
    return {
      telNo: "",
      code: "",
      codeText: "发送验证码",
      Interval: "",
      show: false,
      crossRegisterState: false,
    };
  },
  created() {
    // 走正常流程,判断form表单数据
    let userData = (this.userData = JSON.parse(
      sessionStorage.getItem("userData")
    ));
    if (!userData) {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      // 如果参数丢失，说明操作不对，返回第一页注册
      this.$router.push("/addCardOne");
      return;
    }
  },
  methods: {
    // getCode() {
    //   let reg_tel =
    //     /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
    //   if (!reg_tel.test(this.telNo)) {
    //     this.$notify({ type: "warning", message: "需填写正确的手机号" });
    //     return;
    //   }
    //   if (this.Interval) {
    //     this.$toast.fail("验证码已获取");
    //     return;
    //   }
    //   let second = 60;
    //   this.codeText = second;
    //   this.Interval = setInterval(() => {
    //     second--;
    //     this.codeText = second;
    //     if (second <= 0) {
    //       clearInterval(this.Interval);
    //       this.Interval = "";
    //       this.codeText = "发送验证码";
    //     }
    //   }, 1000);
    //   // 调用验证码接口
    //   console.log("发送验证码咯");
    // },
    onSubmit() {
      // 核对验证码是否正确
      // 进入下一步
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
      if (!reg_tel.test(this.telNo)) {
        this.$notify({ type: "warning", message: "需填写正确的手机号" });
        return;
      }
      this.crossRegisterState = true;
      userData.phone1 = this.telNo;
      let postData = { ...userData };
      let wechatCode = sessionStorage.getItem("wechatCode"); //微信身份码
      let openid = sessionStorage.getItem("openID"); //用户id
      postData.wechatCode = wechatCode;
      postData.openid = openid;
      // postData.patid = this.activePatientId;
      postData.idType = "01";
      this.$healthHttpService
        .post("healthapi/api/health/register", postData)
        .then((res) => {
          this.crossRegisterState = false;
          sessionStorage.removeItem("wechatCode");
          if (res.data.qrCodeText) {
            sessionStorage.removeItem("userData");
            this.$router.push("/");
          }
        })
        .catch((err) => {
          this.crossRegisterState = false;
          this.$toast({
            message: err.response.data,
            duration: 500,
            onClose: () => {
              this.$router.push("/");
            },
          });
        });
      // sessionStorage.setItem("userData", JSON.stringify(userData));
      // this.$router.push("/addCardThree");
    },
  },
};
</script>
<style scoped>
.wrap .getCode {
  max-width: 1.7rem;
  height: 0.5rem;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  line-height: 0.5rem;
  font-size: 0.2rem;
  background-color: white;
  color: #3c44c0;
}
</style>
